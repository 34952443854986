import { ref } from 'vue';
import { DataService } from '@/config/dataService/dataService';

export function useFileDownload() {
    const downloading = ref(false);

    const downloadFile = async (file) => {
        if (downloading.value) {
            console.warn('Download in progress. Please wait.');
            return;
        }

        try {
            downloading.value = true;

            const response = await DataService.getFile(`/api/File/download?Id=${file.id}`);
            const isPDF = file.extension.toLowerCase() === '.pdf';

            if (isPDF) {
                const blob = new Blob([response], { type: "application/pdf" });
                createAndTriggerDownloadLink(blob);
            } else {
                const blob = new Blob([response], { type: "image/png" });
                createAndTriggerDownloadLink(blob);
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        } finally {
            downloading.value = false;
        }
    };

    const createAndTriggerDownloadLink = (blob) => {
        var url = URL.createObjectURL(blob);
        window.open(url, "_blank");
    };

    return { downloadFile, downloading };
}